<template>
  <section class="mb-8">
    <p style="font-size: 24px; color: #404a7a">Creación de proceso compra</p>
    <p>Ejercicio {{ anioFiscal }}</p>
    <v-col cols="12" sm="6">
      <v-col sm="6">
        <v-text-field
          v-model="form.nombre_proceso"
          outlined
          label="Nombre del proceso *"
          @blur="$v.form.nombre_proceso.$touch()"
          @input="$v.form.nombre_proceso.$touch()"
          :error-messages="nombreProcesoError"
        >
        </v-text-field>
      </v-col>
      <v-col sm="4">
        <money-text-field-component
          label="Suma de mercancias *"
          :value="sumaMercancias"
          readonly
        ></money-text-field-component>
      </v-col>
      <v-col sm="5">
        <v-select
          v-model="form.id_modalidad"
          :items="modalidades"
          item-text="nombre"
          item-value="id"
          label="Modalidad de compra *"
          outlined
          @blur="$v.form.id_modalidad.$touch()"
          @input="$v.form.id_modalidad.$touch()"
          :error-messages="modalidadError"
        ></v-select>
      </v-col>
    </v-col>
    <v-col>
      <v-row>
        <v-alert
          color="#404a7a"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-alert-circle-outline"
        >
          <!-- Las solicitudes de OBS incluidas en el proceso fueron solicitadas para
          los meses de: febrero, junio y noviembre de 2022. -->
          {{ obtainMonthsFromProcesos }}
        </v-alert>
      </v-row>
    </v-col>
    <v-col>
      <v-row>
        <v-date-picker
          v-model="picker"
          type="month"
          locale="es-Es"
          header-color="#404a7a"
          style="margin-right: 40px; margin-top: 15px"
          :min="moment().format('YYYY-MM-DD')"
        />
        <v-date-picker
          :min="picker"
          :disabled="picker == ''"
          v-model="picker2"
          type="month"
          locale="es-Es"
          style="margin-top: 15px; color-text: white"
          header-color="#404a7a"
        />
      </v-row>
    </v-col>
    <br />
    <v-col>
      <v-row style="margin-right: 20px">
        <v-btn
          class="mx-15"
          style="margin-top: 15px"
          text
          @click="$router.push({ name: 'proceso-de-compra' })"
        >
          Volver al listado
        </v-btn>
        <v-btn
          class="mx-15"
          color="#404a7a"
          elevation="2"
          dark
          style="margin-top: 15px"
          @click="saveProceso"
        >
          Guardar proceso
        </v-btn>
      </v-row>
    </v-col>
  </section>
</template>

<script>
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { required, helpers } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import moment from "moment";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/); // [A-Za-zñÑáéíóúÁÉÍÓÚ' ]

export default {
  name: "CreacionProcesoPaac",
  components: {
    MoneyTextFieldComponent,
  },
  validations: {
    form: {
      nombre_proceso: { required, letter },
      id_modalidad: { required },
    },
  },
  data: () => ({
    modalidades: [],
    picker: moment().format("YYYY-MM"),
    picker2: null,
    form: {
      nombre_proceso: null,
      id_modalidad: null,
    },
  }),
  computed: {
    ...mapState("paacCrearProcesoCompraStore", ["procesos"]),
    ...mapState(["selectedUnidad", "anioFiscal"]),
    sumaMercancias() {
      let suma = 0;
      this.procesos.forEach((proceso) => {
        proceso.items.forEach((item) => {
          suma += item.total;
        });
      });
      return suma;
    },
    obtainMonthsFromProcesos() {
      let months = [];
      this.procesos.forEach((proceso) => {
        proceso.items.forEach((item) => {
          if (!months.includes(item.mes)) {
            months.push(item.mes);
          }
        });
      });
      if (months.length == 12) {
        return "Las solicitudes de OBS incluidas en el proceso fueron solicitadas para todos los meses del año.";
      } else {
        return `Las solicitudes de OBS incluidas en el proceso fueron solicitadas para los meses de: ${months.join(
          ", "
        )} de 2023.`;
      }
    },
    nombreProcesoError() {
      const errors = [];
      if (!this.$v.form.nombre_proceso.$dirty) return errors;
      !this.$v.form.nombre_proceso.required &&
        errors.push("El nombre del proceso es requerido");
      !this.$v.form.nombre_proceso.letter &&
        errors.push(
          "El nombre del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    modalidadError() {
      const errors = [];
      if (!this.$v.form.id_modalidad.$dirty) return errors;
      !this.$v.form.id_modalidad.required &&
        errors.push("La modalidad de compra es requerida");
      return errors;
    },
  },
  methods: {
    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });
      if (response) {
        this.modalidades = response.data.forma_contratacion;
      }
    },
    async saveProceso() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Por favor, revise los campos marcados en rojo.",
        });
        return;
      }
      if (this.picker > this.picker2) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "La fecha de inicio no puede ser mayor a la fecha final.",
        });
        return;
      }
      let ids = [];
      for (let i = 0; i < this.procesos.length; i++) {
        let proceso = this.procesos[i];
        for (let j = 0; j < proceso.items.length; j++) {
          let item = proceso.items[j];
          ids.push(item.id_solicitud_compra_detalle_obs);
        }
      }

      let params = {
        id_solicitudes_compra_detalle_obs: ids,
        id_forma_contratacion: this.form.id_modalidad,
        nombre_proceso: this.form.nombre_proceso,
        fecha_inicio_proceso: this.moment(this.picker)
          .startOf("month")
          .format("YYYY-MM-DD"),
        fecha_contratacion: this.moment(this.picker2)
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
      const response = await this.services.Paac.postProcesos(params);
      if (response.status == 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Proceso creado exitosamente.",
        });
        this.$router.push({ name: "paac" });
      }
    },
  },
  watch: {
    picker() {
      this.picker2 = this.picker;
    },
  },
  created() {
    if (this.procesos.length === 0) {
      this.$router.push({ name: "proceso-de-compra" });
    } else {
      this.fetchModalidades();
    }
  },
};
</script>
